import {FormattedMessage, Link, navigate, useIntl} from "gatsby-plugin-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Formulario from "../components/formulario";
import {cerrado, horasSR, costosSantaRosa, inputsSantaRosa, feriados} from "../components/defaults";
import React from "react";

const SantaRosaF = () => {
    const sectorACG = "Santa Rosa";
    const intl = useIntl();
    const fechasCerrado = cerrado[sectorACG];
    return (
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "sr.titulo"})}
                 description={intl.formatMessage({id: "sr.info1"})}
                 image={"/images/santa-rosa-cover.jpg"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "SR"})}
                    </Typography> <br />
                    <Typography variant={"body2"}>{intl.formatMessage({id: "sr.info1"})} <br /> <a href={"https://www.acguanacaste.ac.cr/turismo/sector-santa-rosa/sector-santa-rosa"} target={"_blank"} rel="noreferrer">{intl.formatMessage({id: "SR"})}</a> {intl.formatMessage({id: "sr.info3"})}</Typography> <br />
                    <Typography variant={"body2"}>
                        <FormattedMessage
                            id="sr.naranjo"
                            values={{
                                a: text => <a href={"/naranjo"}>{text}</a>,
                            }}
                        />.
                    </Typography>
                </Grid>
            </Grid>
            <Formulario
                sector ={sectorACG}
                horas = {horasSR}
                inputFields =  {inputsSantaRosa}
                costos = {costosSantaRosa}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[2]}
                cerrado = {fechasCerrado}
                tipo={"turistas"}
                iva={1}
                feriados = {feriados}
            />
        </Layout>
    )
}

export default SantaRosaF